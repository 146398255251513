var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "position-relative"
  }, [_c('v-select', {
    ref: "Vueselect",
    class: [{
      'selected-value': _vm.selectedOption,
      'valid-select': _vm.state,
      'invalid-select': _vm.state === false,
      [`select-${_vm.size}`]: _vm.size
    }, _vm.customClass],
    attrs: {
      "inputId": _vm.inputId,
      "options": _vm.optionsSelect,
      "placeholder": _vm.placeholder,
      "components": {
        Deselect: _vm.Deselect
      },
      "label": "label",
      "append-to-body": "",
      "disabled": _vm.disabled,
      "clearable": _vm.clearable
    },
    on: {
      "search:blur": function ($event) {
        return _vm.$emit('blur');
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (option) {
        return [_vm._t("selected-option", null, {
          "option": option
        })];
      }
    }, {
      key: "no-options",
      fn: function ({
        searching
      }) {
        return [searching ? [_vm._v(_vm._s(_vm.$t('message.emptyOption')))] : _vm._e()];
      }
    }, {
      key: "option",
      fn: function (option) {
        return [_vm._t("option", null, {
          "option": option
        })];
      }
    }, {
      key: "open-indicator",
      fn: function ({
        attributes
      }) {
        return [_vm._t("open-indicator", function () {
          return [_c('svg-icon', _vm._b({
            staticClass: "fill-icon mr-2",
            attrs: {
              "size": "20",
              "viewBox": "0 0 20 20",
              "name": "chevron-down"
            }
          }, 'svg-icon', attributes, false))];
        }, {
          "attributes": attributes
        })];
      }
    }], null, true),
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }), _c('b-form-invalid-feedback', {
    class: {
      'position-absolute': _vm.isAbsoluteErrorMessage
    },
    attrs: {
      "state": _vm.state
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };