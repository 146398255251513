export const SENSORTYPE_CHARGE = 'BAT';
export const BOXMODEL_PRO = 'PRO';
export const SUPPORT_PHONE_NUMBER = '+380 96 846 59 45';
export const MIDDLE_OEE_VALUE = 50;
export const HIGH_OEE_VALUE = 75;

export const ROUTE_NAMES = {
  get BASE_LAYOUT() {
    return 'BaseLayout';
  },
};

export const MODE_OF_OPERATION = {
  get ALL_TYPES() {
    return 'ALL_TYPES';
  },
  get UNPLANNED() {
    return 'UNPLANNED';
  },
  get PLANNED() {
    return 'PLANNED';
  },
};

export const PREVIEW_BOX_CARDS_KEY = {
  get KEY_BUY_DEVICE() {
    return 'key-buy-device';
  },
  get KEY_VIEW_DEMO() {
    return 'key-view-demo';
  },
};

export const BREADCRUMB = {
  get OVERVIEW() {
    return 'overview';
  },
  get SETTINGS() {
    return 'settings';
  },
  get PROFILE() {
    return 'profile';
  },
  get COMPANY() {
    return 'company';
  },
  get ANALYTICS() {
    return 'analytics';
  },
  get JOBORDERS() {
    return 'job-orders';
  },
  get OUTPUT() {
    return 'output';
  },
  get LOSSREASONS() {
    return 'loss-reasons';
  },
};

export const BOX_EFFICIENCY = {
  get EFFECTIVE() {
    return 'EFFECTIVE';
  },
  get NOT_EFFECTIVE() {
    return 'NOT_EFFECTIVE';
  },
  get SCHEDULE_LOSS() {
    return 'SCHEDULE_LOSS';
  },
};

export const SORT_OPTIONS = {
  get DESC() {
    return 'DESC';
  },
  get ASC() {
    return 'ASC';
  },
  get NONE() {
    return 'NONE';
  },
};

export const VIEW_MODE_BOX = {
  get LIST_CARD_BOX() {
    return 'LIST_CARD_BOX';
  },
  get CARD_BOX() {
    return 'CARD_BOX';
  },
};

export const DEVICE_STATUS = {
  get TEMPORARY_DISABLED_DEVICE_STATUS() {
    return 'TEMPORARY_SHUTDOWN';
  },
  get ACTIVE() {
    return 'ACTIVE';
  },
  get ARCHIVE() {
    return 'ARCHIVE';
  },
};

export const NOTIFICATIONS_TYPE = {
  get EVENT_DURATION() {
    return 'aa6f5c2c-6cb4-4445-8c91-23013b5e6d3c';
  },
  get TARGET_VALUE() {
    return '5a7b31c0-8ce8-4ba6-826a-1c8ed07f3872';
  },
  get WORK_IN_NON_WORK_TIME() {
    return 'db9d9041-a853-4856-814a-0a6e550f0d9d';
  },
  get BATTERY_LOW() {
    return 'f07be85f-9e05-4c0f-b582-b6e7ff0f14ef';
  },
  get DE_PHASING() {
    return 'f56c3ff2-8063-4822-9991-07af6f9c25f4';
  },
  get DEVICE_OFF() {
    return 'a551b7c7-9406-49ee-a86f-d2bacd44664a';
  },
};

export const REPORT_TIME_TYPE = {
  get DAY() {
    return 'DAY';
  },
  get WEEK() {
    return 'WEEK';
  },
  get MONTH() {
    return 'MONTH';
  },
  get PERIOD() {
    return 'PERIOD';
  },
};

export const REPORT_TYPE = {
  get EFFICIENCY() {
    return 'efficiency';
  },
  get DOWNTIME() {
    return 'downtime';
  },
};
export const SENSOR_ID = {
  get VALUE() {
    return 'VALUE';
  },
  get BATTERY() {
    return 'BATTERY';
  },
  get SIGNAL() {
    return 'SIGNAL';
  },
  get AVG() {
    return 'AVG';
  },
};

export const AR_SENSOR = {
  get TEMPERATURE() {
    return 'STMP';
  },
  get VIBRATION() {
    return 'VIBR';
  },
  get PRESSURE() {
    return 'SPRES';
  },
  get BUTTON_PRESS() {
    return 'BTNPRS';
  },
};

export const SENSOR_UNITS = {
  get TEMPERATURE() {
    return `\u2103`;
  },
  get VIBRATION() {
    return 'Hz';
  },
  get PRESSURE() {
    return 'Pa';
  },
};

export const USER_ROLES = {
  get USER() {
    // return 'OEE_USER';
    return 'beedigit_user';
  },
  get ADMIN() {
    // return 'OEE_PM';
    return 'beedigit_admin';
  },
  get GUEST() {
    return 'GUEST';
  },
};

export const STATUS_TYPE = {
  get WORK_WITH_LOAD() {
    return 'WORK_WITH_LOAD';
  },
  get WORK_WITHOUT_LOAD() {
    return 'WORK_WITHOUT_LOAD';
  },
};

export const STATUS_RANGETYPE = {
  get POWER() {
    return 'POWER';
  },
  get CURRENT() {
    return 'CURRENT';
  },
  get LOAD() {
    return 'LOAD';
  },
};

export const SENSOR_TYPES = {
  get ENERGY_CONSUMPTION() {
    return 'ENERGY_CONSUMPTION';
  },
  get POWER() {
    return 'POWER';
  },
  get ELECTRIC_CURRENT_A() {
    return 'ELECTRIC_CURRENT_A';
  },
  get ELECTRIC_CURRENT_B() {
    return 'ELECTRIC_CURRENT_B';
  },
  get ELECTRIC_CURRENT_C() {
    return 'ELECTRIC_CURRENT_C';
  },
  get LINEAR_SPEED() {
    return 'LINEAR_SPEED';
  },
  get STREAM_OUTPUT() {
    return 'STREAM_OUTPUT';
  },
  get DISCRETE_OUTPUT() {
    return 'DISCRETE_OUTPUT';
  },
  get ACCEPTED_OUTPUT() {
    return 'ACCEPTED_OUTPUT';
  },
  get REJECTED_OUTPUT() {
    return 'REJECTED_OUTPUT';
  },
};

export const OUTPUT_TYPE = {
  get ACCEPTED() {
    return 'ACCEPTED';
  },
  get REJECTED() {
    return 'REJECTED';
  },
};

export const CATEGORY_TYPE = {
  get AVAILABILITY() {
    return 'AVAILABILITY';
  },
  get PERFORMANCE() {
    return 'PERFORMANCE';
  },
  get QUALITY() {
    return 'QUALITY';
  },
};

export const PERIODS_DURATIONS_MS = {
  get ONE_MINUTE() {
    return 60e3;
  },
  get ONE_HOUR() {
    return 3600e3;
  },
  get ONE_DAY() {
    return 86400e3;
  },
  get ONE_WEEK() {
    return 604800e3;
  },
};

export const SPLIT_PERIOD_TYPE = {
  get HOUR() {
    return 'HOUR';
  },
  get DAY() {
    return 'DAY';
  },
  get WEEK() {
    return 'WEEK';
  },
};

export const TARGET_OUTPUT_PERIODS = {
  get HOUR() {
    return 'HOUR';
  },
  get SHIFT() {
    return 'SHIFT';
  },
  get WHOLE_DAY() {
    return 'WHOLE_DAY';
  },
};

export const USER_ACTIONS = {
  get READ() {
    return 'READ';
  },
  get UPDATE() {
    return 'UPDATE';
  },
};

export const USER_SUBJECTS = {
  get ANALYTICS() {
    return 'ANALYTICS';
  },
  get COMPANY_SETTINGS() {
    return 'COMPANY_SETTINGS';
  },
  get COMPANY_USERS() {
    return 'COMPANY_USERS';
  },
  get JOB_ORDERS() {
    return 'JOB_ORDERS';
  },
  get LOSSES() {
    return 'LOSSES';
  },
  get OUTPUTS() {
    return 'OUTPUTS';
  },
  get SHIFTS() {
    return 'SHIFTS';
  },
  get WORK_CENTER_SETTINGS() {
    return 'WORK_CENTER_SETTINGS';
  },
  get API_KEY() {
    return 'API_KEY';
  },
  get NEW_WORK_CENTER() {
    return 'NEW_WORK_CENTER';
  },
  get INTEGRATIONS() {
    return 'INTEGRATIONS';
  },
};

export const JOB_ORDER_STATUSES = {
  get PLANNED() {
    return 'PLANNED';
  },
  get PROGRESS() {
    return 'PROGRESS';
  },
  get COMPLETED() {
    return 'COMPLETED';
  },
  get TECHNICALLY_COMPLETED() {
    return 'TECHNICALLY_COMPLETED';
  },
  get CANCELLED() {
    return 'CANCELLED';
  },
  get DRAFT() {
    return 'DRAFT';
  },
};

export const DURATION_MEASURE_UNITS = {
  get SECONDS() {
    return 'SECONDS';
  },
  get MINUTES() {
    return 'MINUTES';
  },
  get HOUR() {
    return 'HOUR';
  },
};

export const JOBORDERS_IMPORT_TYPE = {
  get REPLACE() {
    return 'REPLACE';
  },
  get UPDATE() {
    return 'UPDATE';
  },
  get RECREATE_AND_UPDATE() {
    return 'RECREATE_AND_UPDATE';
  },
};

export const CONSUMPTION_TRACKING = {
  get NO_TRACKING() {
    return 'NO_TRACKING';
  },
  get WITHOUT_BOM_VALIDATION() {
    return 'WITHOUT_BOM_VALIDATION';
  },
  get WITH_BOM_VALIDATION() {
    return 'WITH_BOM_VALIDATION';
  },
};

export const SHIFT_CONFIRMATION_STATUS = {
  get UNCONFIRMED() {
    return 'UNCONFIRMED';
  },
  get CONFIRMED() {
    return 'CONFIRMED';
  },
  get PROGRESS() {
    return 'PROGRESS';
  },
};

export const MEASUREMENT_UNITS_GROUP = {
  get TIME() {
    return 'TIME';
  },
  get QUANTITY() {
    return 'QUANTITY';
  },
};

export const MEASUREMENT_UNITS_CODES = {
  get SECOND() {
    return 'second';
  },
  get MINUTE() {
    return 'minute';
  },
  get HOUR() {
    return 'hour';
  },
};

export const LOSS_ISSUES = {
  get NO_REASON() {
    return 'NO_REASON';
  },
};

export const OUTPUT_IIOT_ISSUES = {
  get UNCONFIRMED() {
    return 'UNCONFIRMED';
  },
  get NO_PRODUCTION_ORDER() {
    return 'NO_PRODUCTION_ORDER';
  },
};

export const TARGET_OUTPUT_CALCULATION_TYPES = {
  get BY_WORK_CENTER_CYCLE_TIME() {
    return 'BY_WORK_CENTER_CYCLE_TIME';
  },
  get BY_JOB_ORDERS_CYCLE_TIME() {
    return 'BY_JOB_ORDERS_CYCLE_TIME';
  },
  get BY_CURRENT_JOB_ORDER_CYCLE_TIME() {
    return 'BY_CURRENT_JOB_ORDER_CYCLE_TIME';
  },
};

export const NOTIFICATION_TARGET_AUDIENCE = {
  get USERS() {
    return 'USERS';
  },
  get TEAMS() {
    return 'TEAMS';
  },
  get ROLES() {
    return 'ROLES';
  },
};

export const LOSS_REASON_TYPE = {
  get PLANNED() {
    return 'PLANNED';
  },
  get UNPLANNED() {
    return 'UNPLANNED';
  },
};

export const LOSS_REASON_LINK_TYPE = {
  get COMPANY() {
    return 'COMPANY';
  },
  get WORK_CENTER_GROUP() {
    return 'WORK_CENTER_GROUP';
  },
  get WORK_CENTER() {
    return 'WORK_CENTER';
  },
};

export const LOSS_REASON_ID = {
  get OTHER() {
    return 'OTHER';
  },
  get MANUAL() {
    return 'MANUAL';
  },
};

export const INTEGRATION_SERVICES = {
  get FACTORY_ONLINE() {
    return 'FACTORY_ONLINE';
  },
  get ODOO() {
    return 'ODOO';
  },
};

export const INTEGRATION_STATUS = {
  get DISABLED() {
    return 'DISABLED';
  },
  get PROCESSING() {
    return 'PROCESSING';
  },
  get SETTING_UP() {
    return 'SETTING_UP';
  },
  get FAILED() {
    return 'FAILED';
  },
  get CONNECTED() {
    return 'CONNECTED';
  },
};
