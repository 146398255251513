import Vue from 'vue';
import { computed, getCurrentInstance } from 'vue';
import VueI18n from 'vue-i18n';
import en from '../langs/en.json';
import uk from '../langs/uk.json';
import pl from '../langs/pl.json';
import ru from '../langs/ru.json';
import es from '../langs/es.json';

Vue.use(VueI18n);

export function useI18n() {
  const instance = getCurrentInstance();
  const vm = instance?.proxy || instance || new Vue({});

  const locale = computed({
    get() {
      return i18n.locale;
    },
    set(v) {
      i18n.locale = v;
    },
  });

  return {
    locale,
    availableLocales: ['en', 'uk', 'pl', 'ru', 'es'],
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm),
  };
}

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages: {
    en,
    uk,
    pl,
    ru,
    es,
  },
});
