import Header from './components/TheHeader';
import Sidebar from './components/TheSidebar';
import { useTimeZoneStorage } from '@/composables/date/useTimeZoneStorage';
import useUserSettings from '@/composables/useUserSettings.query';
import { setGlobalTimeZone } from '@/utils/dateTimeFns';
import { ref, watchEffect } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const timeZoneLoaded = ref(false);
    const {
      result: userSettings
    } = useUserSettings();
    const {
      timeZone
    } = useTimeZoneStorage();
    watchEffect(() => {
      if (userSettings.value?.timeZone) {
        timeZone.value = userSettings.value.timeZone;
        setGlobalTimeZone(userSettings.value.timeZone);
        timeZoneLoaded.value = true;
      }
    });
    return {
      __sfc: true,
      timeZoneLoaded,
      userSettings,
      timeZone,
      Header,
      Sidebar
    };
  }
};