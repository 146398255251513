import {
  format,
  subDays as fnSubDays,
  startOfDay as fnStartOfDay,
  isSameDay as fnIsSameDay,
  differenceInMinutes,
  formatDistance as fnFormatDistance,
} from 'date-fns';
import {
  fromZonedTime as fnFromZonedTime,
  toZonedTime as fnToZonedTime,
  getTimezoneOffset as fnGetTimezoneOffset,
} from 'date-fns-tz';

let currentTimeZone = 'UTC';

export function getDateTimeNow() {
  return fnToZonedTime(new Date(), currentTimeZone);
}

export function formatDateTime(date, formatStr) {
  const zonedDate = fnToZonedTime(date, currentTimeZone);
  return format(zonedDate, formatStr);
}

export function subDays(date, amount) {
  const zonedDate = fnToZonedTime(date, currentTimeZone);
  return fnSubDays(zonedDate, amount);
}

export function startOfDay(date) {
  const zonedDate = fnToZonedTime(date, currentTimeZone);
  return fnStartOfDay(zonedDate);
}

export function isSameDay(dateLeft, dateRight) {
  const zonedLeft = fnToZonedTime(dateLeft, currentTimeZone);
  const zonedRight = fnToZonedTime(dateRight, currentTimeZone);
  return fnIsSameDay(zonedLeft, zonedRight);
}

export function getMinutesDifference(dateLeft, dateRight) {
  const zonedLeft = fnToZonedTime(dateLeft, currentTimeZone);
  const zonedRight = fnToZonedTime(dateRight, currentTimeZone);
  return differenceInMinutes(zonedLeft, zonedRight);
}

export function formatDistance(dateLeft, dateRight) {
  const zonedLeft = fnToZonedTime(dateLeft, currentTimeZone);
  const zonedRight = fnToZonedTime(dateRight, currentTimeZone);
  return fnFormatDistance(zonedLeft, zonedRight, { addSuffix: true });
}

export function getMiddleDateTime(start, end) {
  const zonedStart = fnToZonedTime(start, currentTimeZone);
  const zonedEnd = fnToZonedTime(end, currentTimeZone);
  const startMs = zonedStart.getTime();
  const endMs = zonedEnd.getTime();
  return new Date(startMs + (endMs - startMs) / 2);
}

// function setDefaultLocale(locale = 'en') {
//   Settings.defaultLocale = locale;
// }

export function setGlobalTimeZone(timeZone) {
  currentTimeZone = timeZone;
}

export function fromZonedTime(date) {
  return fnFromZonedTime(date, currentTimeZone);
}

export function toZonedTime(date) {
  return fnToZonedTime(date, currentTimeZone);
}

export function getTimezoneOffset() {
  return fnGetTimezoneOffset(currentTimeZone);
}
