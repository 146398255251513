var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_setup.timeZoneLoaded ? _c('div', {
    staticClass: "container-scroller overflow-auto flex-shrink-1 position-relative",
    attrs: {
      "id": "container-scroller"
    }
  }, [_c(_setup.Header), _c(_setup.Sidebar), _c('div', {
    staticClass: "container-fluid page-body-wrapper"
  }, [_c('div', {
    staticClass: "main-panel"
  }, [_c('div', {
    staticClass: "content-wrapper",
    attrs: {
      "id": "main-container"
    }
  }, [_c('div', {
    staticClass: "card fullHeight"
  }, [_c('div', {
    staticClass: "card-body p-0"
  }, [_c('router-view')], 1)])])])])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };