export default {
  name: 'form-vue-select',
  props: {
    value: {
      type: [String, Number]
    },
    optionsSelect: Array,
    customClass: String,
    isVisiblePlaceholder: Boolean,
    errorMessage: String,
    inputId: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: null
    },
    isAbsoluteErrorMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  mounted() {
    this.containerScroller = document.getElementById('container-scroller');
    this.containerScroller?.addEventListener('scroll', this.closeDropdown);
    this.modalBody = document.querySelector('.modal-body');
    this.modalBody?.addEventListener('scroll', this.closeDropdown);
  },
  destroyed() {
    this.containerScroller?.removeEventListener('scroll', this.closeDropdown);
    this.modalBody?.removeEventListener('scroll', this.closeDropdown);
  },
  data() {
    return {
      containerScroller: null,
      modalBody: null,
      Deselect: {
        render: createElement => createElement('span', {
          class: 'text-light',
          on: {
            click: () => {
              this.$emit('clearOption');
            }
          }
        }, '✕')
      }
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.optionsSelect.find(option => option.value == this.value);
      },
      set(val) {
        this.$emit('selectOption', val);
        this.$emit('input', val?.value ?? '');
      }
    }
  },
  methods: {
    closeDropdown() {
      this.$refs.Vueselect.open = false;
    }
  }
};