var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": _vm.routerTo
    }
  }, [_c('span', {
    staticClass: "menu-icon"
  }, [_c('svg-icon', {
    class: _vm.iconClass,
    attrs: {
      "name": _vm.icon,
      "viewBox": _vm.viewBox,
      "size": "20"
    }
  })], 1), _c('div', {
    staticClass: "menu-title-container"
  }, [_c('span', {
    staticClass: "menu-title"
  }, [_vm._v(_vm._s(_vm.title))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };