import { ApolloClient, InMemoryCache, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { buildClientSchema } from 'graphql';
import auth from '@/plugins/auth-service';
import { i18n } from '@/plugins/i18n';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { withScalars } from 'apollo-link-scalars';
import { toZonedTime, fromZonedTime } from '@/utils/dateTimeFns';
import Observable from 'zen-observable';

const removeTypenameLink = removeTypenameFromVariables();

import introspectionResult from '@/gql/graphql.schema.json';

const schema = buildClientSchema(introspectionResult);

export const createApoloClient = (grapgUri, schemaCode, connectToDevTools) => {
  const httpLink = new HttpLink({
    uri: grapgUri,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await auth.getAccessToken();
    return {
      headers: {
        ...headers,
        'Accept-Language': i18n.locale,
        Authorization: `Bearer ${token}`,
        schemaCode,
      },
    };
  });

  const onErrorLink = onError(({ networkError, operation, forward }) => {
    if (networkError?.statusCode === 401) {
      const redirectPromise = new Promise((resolve, reject) => {
        auth
          .getUser()
          .then((user) => {
            if (!user) {
              resolve("User doesn't exist");
            }

            auth
              .signoutRedirect({ id_token_hint: user?.id_token || '' })
              .then(resolve)
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });

      return new Observable((subscriber) => {
        redirectPromise.then(
          (value) => {
            if (subscriber.closed) return;
            subscriber.next(value);
            subscriber.complete();
          },
          (err) => subscriber.error(err),
        );
        return;
      }).flatMap(() => forward(operation));
    }
  });

  const typesMap = {
    DateTime: {
      serialize: (parsed) => {
        const date = new Date(parsed);

        if (date instanceof Date) {
          return fromZonedTime(date).toISOString();
        }

        return parsed;
      },
      parseValue: (raw) => {
        if (!raw) return null;

        if (typeof raw === 'string') {
          return toZonedTime(raw);
        }

        throw new Error('invalid value to parse');
      },
    },
  };

  return new ApolloClient({
    link: from([
      removeTypenameLink,
      authLink,
      withScalars({ schema, typesMap }),
      onErrorLink,
      httpLink,
    ]),
    cache: new InMemoryCache(),
    connectToDevTools,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};
