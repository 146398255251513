import en from './en.json';
import uk from './uk.json';
import pl from './pl.json';
import ru from './ru.json';
import es from './es.json';
import { createLocale } from '../../utils.js';

const locales = {
  en: createLocale(en, 'shifts'),
  uk: createLocale(uk, 'shifts'),
  pl: createLocale(pl, 'shifts'),
  ru: createLocale(ru, 'shifts'),
  es: createLocale(es, 'shifts'),
};

export default locales;
