import { ref } from 'vue';
import { useI18n } from '@/plugins/i18n';
import { useLocalStorage } from '@vueuse/core';
import { apolloProvider } from '@/plugins/apollo';
import { useMoment } from '@/plugins/moment';
const { locale } = useI18n();

export default function useLocale() {
  const currentLocale = ref(locale);
  const storageLocale = useLocalStorage('userLocale', 'en');
  const { setMomentLocale } = useMoment();
  const setLocale = async (newLocale) => {
    currentLocale.value = newLocale;
    storageLocale.value = newLocale;
    setMomentLocale(newLocale);
    await apolloProvider.defaultClient.reFetchObservableQueries();
  };

  const restoreLocale = () => {
    currentLocale.value = storageLocale.value;
    setMomentLocale(currentLocale.value);
  };

  return {
    locale: currentLocale,
    setLocale,
    restoreLocale,
  };
}
