import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApoloClient } from './itApolloClientFactory.js';
import { provideApolloClient } from '@vue/apollo-composable';

const graphqlUri = process.env.VUE_APP_API_SERVER_PATH + 'api/graphql';

const beedigitApolloClient = createApoloClient(graphqlUri, 'BEEDIGIT', true);

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: beedigitApolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

provideApolloClient(apolloProvider.defaultClient);

export function apolloClientClearCache() {
  return beedigitApolloClient.clearStore();
}

export { apolloProvider };
