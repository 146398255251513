import moment from 'moment';

export function useMoment() {
  function setMomentLocale(locale = 'en') {
    moment.updateLocale(locale, {
      week: {
        dow: 1, // Monday is first day of week
      },
    });
  }
  return {
    setMomentLocale,
  };
}
