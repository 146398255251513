import { ROUTE_NAMES } from '@/utils/const.js';
import { USER_ACTIONS, USER_SUBJECTS } from '@/utils/const';
const Integrations = () => import('./pages');

const moduleRoute = {
  path: '/integrations',
  name: 'Integrations',
  component: Integrations,
  meta: {
    allowAnonymous: false,
    ability: {
      subject: USER_SUBJECTS.INTEGRATIONS,
      action: USER_ACTIONS.READ,
    },
  },
};

export default (router) => {
  router.addRoute(ROUTE_NAMES.BASE_LAYOUT, moduleRoute);
};
